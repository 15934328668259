import React, { useEffect, useState, useMemo } from "react";
import CustomTable from "../CustomTable";
import { Button } from "react-bootstrap";
import Loading from "../utils/Loading";
import RecordCount from "../utils/RecordCount";
import FormModal from "../FormModal";
import JiraTicket from "../JiraTicket";

const Entities = (props) => {
  const [data, setData] = useState(props.entities);
  const [isLoading, setIsLoading] = useState(true);
  const [showModalMakePrimary, setShowModalMakePrimary] = useState(false);
  const [modalMakePrimaryId, setModalMakePrimaryId] = useState(null);
  const [showModalRemoveEntity, setShowModalRemoveEntity] = useState(false);
  const [modalRemoveEntityId, setModalRemoveEntityId] = useState(null);

  useEffect(() => {
    if (!data) {
      const fetchData = async () => {
        try {
          const response = await fetch(props.get_entities_path, {
            headers: { Accept: "application/json" },
          });
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const fetchedData = await response.json();
          setData(fetchedData);
        } catch (error) {
          console.log("Error fetching data: ", error);
        } finally {
          setIsLoading(false);
        }
      };
      if (!data || data.length === 0) {
        fetchData();
      } else {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, []);

  const MakePrimaryFormFields = () => {
    return (
      <React.Fragment>
        <input type="hidden" name="id_340b" value={modalMakePrimaryId} />
        <p>
          Are you sure you want to change the primary entity from {props.primary_entity_id_340b} to{" "}
          {modalMakePrimaryId}?
        </p>
        <JiraTicket />
      </React.Fragment>
    );
  };

  const RemoveEntityFormFields = () => {
    const message = `Are you sure you want to remove entity ${modalRemoveEntityId} from this organization?`;
    const warning =
      props.primary_entity_id_340b == modalRemoveEntityId && props.users_count > 0
        ? ` ${props.users_count} user(s) will lose access to the 340B platform.`
        : "";

    return (
      <React.Fragment>
        <input type="hidden" name="organization_id" value={props.organization_id} />
        <input type="hidden" name="id_340b" value={modalRemoveEntityId} />
        <p>{message}</p>
        <p>{warning}</p>
        <JiraTicket />
      </React.Fragment>
    );
  };

  function entityLink(props, tableProps) {
    var entity_path = "/entities/" + tableProps.row.original.id;
    if (props.tab) {
      entity_path = entity_path + "?tab=" + props.tab;
    } else if (!tableProps.row.original.organization_id) {
      entity_path = entity_path + "?tab=validations";
    }
    return entity_path;
  }

  function isCurrentPrimary(props, tableProps) {
    return props.primary_entity_id_340b == tableProps.row.original.id_340b;
  }

  function canRemoveEntity(props, tableProps) {
    // Only allow removing Primary entity if it is the last entity in the organization
    return !isCurrentPrimary(props, tableProps) || props.entities.length == 1;
  }

  // Only show "Primary?" column on Org tab
  const columns = useMemo(
    () =>
      [
        props.organization_id
          ? {
              Header: "Primary?",
              Cell: (tableProps) => (
                <div className="ms-2">
                  {isCurrentPrimary(props, tableProps) ? (
                    <i className="text-primary fa fa-award"></i>
                  ) : null}
                </div>
              ),
            }
          : null,
        {
          Header: "340B ID",
          accessor: "id_340b",
          filter: "fuzzyText",
          Cell: (tableProps) => (
            <a href={`/entities/${tableProps.row.original.id}`}>{tableProps.value}</a>
          ),
        },
        {
          Header: "Covered Entity",
          accessor: (row) =>
            `${row.entity_name} ${row.address1} ${row.city}, ${row.state}, ${row.designation_permitted_status}`,
          filter: "fuzzyText",
          Cell: (tableProps) => (
            <>
              <b>{tableProps.row.original.entity_name}</b>
              <br />
              <small className="text-muted">
                {tableProps.row.original.address1}, {tableProps.row.original.city},{" "}
                {tableProps.row.original.state}
              </small>
            </>
          ),
        },
        {
          Header: "Des Permitted Status",
          accessor: "designation_permitted_status",
          filter: "fuzzyText",
          Cell: (tableProps) => (
            <small className="text-muted badge bg-light">
              {tableProps.row.original.designation_permitted_status || "N/A"}
            </small>
          ),
        },
        {
          Header: "Actions",
          id: "id",
          Filter: () => {},
          Cell: (tableProps) => (
            <div className="text-nowrap">
              <a href={entityLink(props, tableProps)}>
                <Button className="btn btn-sm btn-primary">View</Button>
              </a>
              <>
                {props.can_set_primary && !isCurrentPrimary(props, tableProps) ? (
                  <Button
                    className="btn btn-sm btn-warning ms-2"
                    onClick={() => {
                      setModalMakePrimaryId(tableProps.row.original.id_340b);
                      setShowModalMakePrimary(true);
                    }}
                  >
                    Make Primary
                  </Button>
                ) : null}
                {props.is_developer_support ? (
                  <span
                    title={`${
                      canRemoveEntity(props, tableProps)
                        ? ""
                        : "Cannot remove primary entity while other entities are present"
                    }`}
                  >
                    <Button
                      className={`btn btn-sm btn-danger ms-2 ${
                        canRemoveEntity(props, tableProps) ? "" : "disabled"
                      }`}
                      disabled={!canRemoveEntity(props, tableProps)}
                      onClick={() => {
                        setModalRemoveEntityId(tableProps.row.original.id_340b);
                        setShowModalRemoveEntity(true);
                      }}
                    >
                      Remove
                    </Button>
                  </span>
                ) : null}
              </>
            </div>
          ),
        },
      ].filter((n) => n),
    [],
  );

  return (
    <React.Fragment>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {!props.hide_count ? <RecordCount record_count={data.length} /> : null}
          {data.length > 0 ? <CustomTable columns={columns} data={data} /> : null}
          <FormModal
            show={showModalMakePrimary}
            title="Make Primary Entity"
            buttonText="Make Primary"
            buttonType="warning"
            modalId={`make_primary_${modalMakePrimaryId}`}
            method="POST"
            fields={<MakePrimaryFormFields />}
            action={`/organizations/${props.organization_id}`}
            dismiss={() => {
              setShowModalMakePrimary(false);
            }}
          />
          <FormModal
            show={showModalRemoveEntity}
            title="Remove Entity"
            buttonText="Remove"
            buttonType="danger"
            modalId={`remove_entity_${modalRemoveEntityId}`}
            method="POST"
            fields={<RemoveEntityFormFields />}
            action={`/entities/${modalRemoveEntityId}/remove_entity_from_organization`}
            dismiss={() => {
              setShowModalRemoveEntity(false);
            }}
          />
        </>
      )}
    </React.Fragment>
  );
};

export default Entities;
