import React from "react";
import { useState, useMemo } from "react";
import FormModal from "../FormModal";
import Activities from "../Activities";
import EntityValidations from "./EntityValidations";
import InHouseStatus from "./InHouseStatus";
import WhollyOwnedApplications from "../WhollyOwnedApplications/WhollyOwnedApplications";
import Designations from "../Designations/Designations";
import CreateDesignationButton from "../Designations/CreateDesignationButton";
import Pharmacies from "./Pharmacies";
import EffectiveDates from "../EffectiveDates/EffectiveDates";
import CreateEffectiveDate from "../EffectiveDates/CreateEffectiveDate";
import { Form, Tab, Tabs } from "react-bootstrap";
import Users from "../Users/Users";
import ChildSites from "./ChildSites";
import Entities from "./Entities";

const Entity = (props) => {
  const [showInviteUserModal, setShowInviteUserModal] = useState(false);

  return (
    <React.Fragment>
      {props.entity.designation_permitted_status == "has_inhouse_pharmacy" ||
      props.in_house_designations_exist ? (
        <InHouseStatus
          entity={props.entity}
          in_house_designations_exist={props.in_house_designations_exist}
        />
      ) : null}
      <Tabs
        defaultActiveKey={props.params.tab || "activities"}
        id="covered_entity_tabs"
        className="mb-3"
      >
        <Tab
          eventKey="activities"
          title={
            <>
              <i className="fa fa-list"></i> Activity
            </>
          }
        >
          <Activities activities_path={props.activities_path} />
        </Tab>
        <Tab
          eventKey="pharmacies"
          title={
            <>
              <i className="fa fa-prescription-bottle"></i> Pharmacies{" "}
              <span className="badge bg-light text-dark">{props.pharmacies_count}</span>
            </>
          }
        >
          <Pharmacies pharmacies_path={props.pharmacies_path} />
        </Tab>
        <Tab
          eventKey="designations"
          title={
            <>
              <i className="fa fa-map-pin"></i> Designations{" "}
              <span className="badge bg-light text-dark">{props.designations_count}</span>
            </>
          }
        >
          {props.can_edit ? (
            <div className="mb-3">
              <small className="text-muted">Want to create a new designation?</small>
              <CreateDesignationButton
                manufacturers={props.designation_config.manufacturers}
                designation_kinds={props.designation_config.designation_kinds}
                id_340b={props.entity.id_340b}
                create_designation_path={props.designation_config.create_designation_path}
              />
            </div>
          ) : null}
          <Designations
            hide_count="true"
            designations_path={props.designations_path}
            flag_options={props.designation_config.flag_options}
            can_edit={props.can_edit}
            can_assign={props.can_assign}
          />
        </Tab>
        <Tab
          eventKey="users"
          title={
            <>
              <i className="fa fa-user-plus"></i> Users{" "}
              <span className="badge bg-light text-dark">{props.users_count}</span>
            </>
          }
        >
          <div className="mb-3">
            <small className="text-muted">Want to invite a new user to this covered entity?</small>
            <button
              className="btn btn-primary ms-2 btn-sm"
              onClick={() => {
                setShowInviteUserModal(true);
              }}
            >
              <i className="fa fa-user me-1"></i>
              Invite User
            </button>
          </div>
          <Users
            users_path={props.users_path}
            hide_count="true"
            id_340b={props.entity.id_340b}
            is_developer_support={props.is_developer_support}
          />
        </Tab>
        <Tab
          eventKey="validations"
          title={
            <>
              <i className="fa fa-clipboard-check"></i> Validations{" "}
              <span className="badge bg-light text-dark">{props.entity_validations.length}</span>
            </>
          }
        >
          <EntityValidations
            entity_validations={props.entity_validations}
            entity={props.entity}
            users={props.ce_users}
            can_edit={props.can_edit}
          />
        </Tab>
        {props.wholly_owned_applications_count > 0 ? (
          <Tab
            eventKey="wo_applications"
            title={
              <>
                <i className="fa fa-file-lines"></i> WO Applications{" "}
                <span className="badge bg-light text-dark">
                  {props.wholly_owned_applications_count}
                </span>
              </>
            }
          >
            <WhollyOwnedApplications
              applications_path={props.wholly_owned_applications_path}
              hide_count="true"
            />
          </Tab>
        ) : null}
        <Tab
          eventKey="effective_dates"
          title={
            <>
              <i className="fa fa-calendar-check"></i> Effective Dates{" "}
              <span className="badge bg-light text-dark">{props.effective_dates_count}</span>
            </>
          }
        >
          {props.can_edit ? (
            <p>
              Want to add a new effective date?
              <CreateEffectiveDate
                manufacturers={props.manufacturers}
                manufacturer_networks={props.manufacturer_networks}
                id_340b={props.entity.id_340b}
              />
            </p>
          ) : null}
          <EffectiveDates
            effective_dates_path={props.effective_dates_path}
            manufacturer_networks={props.manufacturer_networks}
            covered_entity={props.entity}
            manufacturers={props.manufacturers}
            hide_count="true"
            can_edit={props.can_edit}
          />
        </Tab>
        <Tab
          eventKey="organization"
          title={
            <>
              <i className="fa fa-sitemap"></i> Org
            </>
          }
        >
          <Entities
            can_set_primary={props.can_set_primary}
            entities={props.organization_entities}
            organization_id={props.organization_id}
            primary_entity_id_340b={props.primary_entity_id_340b}
            users_count={props.users_count}
            is_developer_support={props.is_developer_support}
          />
        </Tab>
        {(!props.entity.child_site && !props.entity.parent_id_340b) ||
        (props.entity.child_site === false && !props.entity.parent_id_340b) ? (
          <Tab
            eventKey="child_sites"
            title={
              <>
                <i className="fa fa-th-list"></i> Child Sites
              </>
            }
          >
            <ChildSites child_sites={props.child_sites} current_parent_site={props.entity} />
          </Tab>
        ) : null}
      </Tabs>
      <FormModal
        show={showInviteUserModal}
        title="Invite User"
        buttonText="Submit"
        buttonType="primary"
        modalId="invite_user_modal"
        fields={<InviteUserFormFields />}
        method="POST"
        action={`/entities/${props.entity.id}/invite_user`}
        dismiss={() => {
          setShowInviteUserModal(false);
        }}
      />
    </React.Fragment>
  );
};

const InviteUserFormFields = (props) => {
  return (
    <React.Fragment>
      <p>
        <small className="text-danger">* Required</small>
      </p>
      <Form.Group className="mb-3" controlId="email">
        <Form.Label>
          Email <small className="text-danger">*</small>
        </Form.Label>
        <Form.Control required name="user[email]" placeholder="Enter email..." />
      </Form.Group>
      <p className="mt-2">
        <small className="text-muted">
          <b>Note:</b> An entity validation will be created for this email domain, if it does not
          already exist. Please ensure email is correct.
        </small>
      </p>
      <Form.Group className="mb-3" controlId="email_confirmation">
        <Form.Label>
          Confirm Email <small className="text-danger">*</small>
        </Form.Label>
        <Form.Control required name="user[email_confirmation]" placeholder="Confirm email..." />
      </Form.Group>
      <Form.Group className="mb-3" controlId="first_name">
        <Form.Label>
          First Name <small className="text-danger">*</small>
        </Form.Label>
        <Form.Control required name="user[first_name]" placeholder="Enter first name..." />
      </Form.Group>
      <Form.Group className="mb-3" controlId="last_name">
        <Form.Label>
          Last Name <small className="text-danger">*</small>
        </Form.Label>
        <Form.Control required name="user[last_name]" placeholder="Enter last name..." />
      </Form.Group>
      <Form.Group className="mb-3" controlId="role">
        <Form.Label>
          Role <small className="text-danger">*</small>
        </Form.Label>
        <Form.Select required name="user[role]" as="select">
          <option value="user">User</option>
          <option value="admin">Admin</option>
        </Form.Select>
      </Form.Group>
    </React.Fragment>
  );
};

export default Entity;
