import React from "react";
import { useMemo } from "react";
import CustomTable from "../CustomTable";
import RecordCount from "../utils/RecordCount";

const Ndcs = (props) => {
  const columns = useMemo(
    () => [
      {
        Header: "Manufacturer",
        accessor: "manufacturer",
        filter: "fuzzyText",
      },
      {
        Header: "Product Name",
        accessor: "product_name",
        filter: "fuzzyText",
      },
      {
        Header: "Item",
        accessor: "item",
        filter: "fuzzyText",
      },
      {
        Header: "Format",
        accessor: "format",
        filter: "fuzzyText",
      },
      {
        Header: "Network",
        accessor: "network",
        filter: "fuzzyText",
        Cell: (tableProps) => (
          <span className="badge bg-secondary">{tableProps.row.original.network}</span>
        ),
      },

    ],
    [],
  );

  return (
    <React.Fragment>
      {!props.hide_count ? <RecordCount record_count={props.ndcs.length} /> : null}
      {props.ndcs.length > 0 || props.hide_count ? <CustomTable columns={columns} data={props.ndcs} /> : null}
    </React.Fragment>
  );
};

export default Ndcs;
