import React from "react";

const NdcsHeader = (props) => {
  return (
    <React.Fragment>
      <div className="pt-3 pb-2 row">
        <div className="col-9">
          <h1 className="h2">
            <i className="fa fa-capsules pe-2"></i>
            National Drug Codes (NDCs)
          </h1>
        </div>
      </div>
      <p className="text-muted">National Drug Codes by Manufacturer.</p>
    </React.Fragment>
  );
};

export default NdcsHeader;
