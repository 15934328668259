import React from "react";
import WhollyOwnedApplications from "../WhollyOwnedApplications/WhollyOwnedApplications";
import { Tab, Tabs } from "react-bootstrap";
import Ldns from "../Ldns/Ldns";
import Ndcs from "../Ndcs/Ndcs";
import BlockList from "../Blocked/BlockList";
import EffectiveDates from "../EffectiveDates/EffectiveDates";
import Entities from "../Entities/Entities";
import Pharmacies from "../Entities/Pharmacies";

const Manufacturer = (props) => {
  return (
    <React.Fragment>
      <Tabs
      defaultActiveKey="ldns"
      id="manufacturer_tabs"
      className="mb-3"
      >
      <Tab eventKey="ldns" title={<><i className="fa fa-network-wired"></i> LDNs</>}>
        <Ldns ldn={props.ldns} can_edit={props.can_edit} />
      </Tab>
      <Tab eventKey="ndcs" title={<><i className="fa fa-capsules"></i> NDCs</>}>
        <Ndcs ndcs={props.ndcs} can_edit={props.can_edit} />
      </Tab>
      <Tab eventKey="covered_entities" title={<><i className="fa fa-hospital"></i> Covered Entities</>}>
        <Entities get_entities_path={props.covered_entities_path}
        />
      </Tab>
      <Tab eventKey="block_list" title={<><i className="fa fa-lock"></i> Block List</>}>
        <BlockList blocked={props.blocked_items} can_edit={props.can_edit} />
      </Tab>
      <Tab eventKey="effective_dates" title={<><i className="fa fa-calendar-check"></i> Effective Dates</>}>
        <EffectiveDates effective_dates_path={props.effective_dates_path} can_edit={props.can_edit} />
      </Tab>
      { props.applications_count > 0 ?
        <Tab eventKey="wo_applications" title={<><i className="fa fa-file-lines"></i> WO Applications</>}>
          <WhollyOwnedApplications applications_path={props.applications_path} />
        </Tab>
      : null }
    </Tabs>
    </React.Fragment>
  );
};


export default Manufacturer;
